<section class="sections">

    <div class="container" *ngIf="youtubeVideo">

        <h1 class="header1Posts">
            {{youtubeVideo.title}}
        </h1> 
        <h4>{{youtubeVideo.publishedAt  | date :'short'}}</h4>
        <mat-divider style="padding: 1rem;"></mat-divider>
        <div class="row">
            <div class="col-sm-8">
                <div class="conteudo-noticia">
                    <iframe width="100%" height="400" [src]="url" title="YouTube video player" frameborder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>

                    <p>
                        {{youtubeVideo.description}}
                    </p>
                </div>
            </div>

            <div class="col-sm">
                <div class="card bg-white mb-3" style="max-width: 18rem;">
                    <div class="card-header">
                        <h2 style="padding: 0; margin: 0">
                        <a [routerLink]="['/falecimentos/']">    +YOUTUBE</a>
                        </h2>
                    </div>
                    <div class="card-body">
                        <ng-container  *ngFor="let video of youtubeVideoList">
                        <a [routerLink]="['/youtube/' + video.videoId]"><h4 class="card-title">{{video.title}}
                        </h4>
                            <mat-divider class="divider"></mat-divider>
                        </a>
                        </ng-container>

                    </div>
                </div>
            </div>
        </div>

    </div>
</section>

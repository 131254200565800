import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Youtube } from "src/models/Youtube";
import { NewsProvider } from "src/providers/news/newsProvider";
import { MetaAndTitle } from "../shared/metaAndTitle/metaAndTitle";
import { DomSanitizer } from "@angular/platform-browser";
import localbr from "@angular/common/locales/br";

@Component({
  selector: "app-youtube",
  templateUrl: "./youtube.component.html",
  styleUrls: ["./youtube.component.css"],
})
export class YoutubeComponent implements OnInit {
  public youtubeVideo: Youtube;
  public youtubeVideoList: Youtube[];
  url: any;
  localbr = localbr;

  constructor(
    private activatedRoute: ActivatedRoute,
    private metaAndTitle: MetaAndTitle,
    private sanitizer: DomSanitizer,
    private newsProvider: NewsProvider
  ) {}

  ngOnInit(): void {
    this.getVideoId();
  }

  getVideoId() {
    this.activatedRoute.params.subscribe((params) => {
      this.getYoutubeVideoById(params["id"]);
    });
  }

  getLastChannelVideos() {
    this.newsProvider.getYoutubeVideoList().then((youtubeVideos: Youtube[]) => {
      this.youtubeVideoList = youtubeVideos;
    });
  }

  getYoutubeVideoById(id) {
    this.newsProvider
      .getYoutubeVideoId(id)
      .then((result: Youtube) => {
        this.metaAndTitle.setMetaAndTitle(
          result.title,
          "/youtube/" + result.videoId,
          undefined,
          result["thumbnailUrl"],
          "Transmissões",
          "Youtube"
        );
        this.youtubeVideo = result;
        this.url = this.sanitizer.bypassSecurityTrustResourceUrl(
          this.youtubeVideo.url
        );
        this.getLastChannelVideos();
      })
      .catch((reason) => console.error(reason));
  }
}
